import React from "react";
import CardL from "./CardL";
import { Disclosure } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";

export default function App() {
  const navigation = [
    { name: "Home", href: "", current: true },
    { name: "Lesesalen", href: "lesesalen", current: false },
    { name: "Breakfast", href: "brekkie", current: false },
    { name: "MicrloBib", href: "micrlobib", current: false },
    { name: "Complonents", href: "complonents", current: false },
    {
      name: "Github",
      href: "https://github.com/HenrikEide",
      current: false,
    },
  ];

  function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(" ");
  }

  return (
    <>
      <Disclosure as="nav" className="bg-gray-800">
        {({ open }) => (
          <>
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
              <div className="flex h-16 items-center justify-between">
                <div className="flex items-center">
                  <div className="flex-shrink-0">
                    <img
                      className="h-8 w-8"
                      src="https://www.clipartmax.com/png/middle/27-274923_coffee-cup-cappuccino-cafe-hot-coffee-vector-png.png"
                      alt="Some logo"
                    />
                  </div>
                  <div className="hidden md:block">
                    <div className="ml-10 flex items-baseline space-x-4">
                      {navigation.map((item) => (
                        <a
                          key={item.name}
                          href={item.href}
                          className={classNames(
                            item.current
                              ? "bg-gray-900 text-white"
                              : "text-gray-300 hover:bg-gray-700 hover:text-white",
                            "px-3 py-2 rounded-md text-sm font-medium"
                          )}
                          aria-current={item.current ? "page" : undefined}
                        >
                          {item.name}
                        </a>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="-mr-2 flex md:hidden">
                  {/* Mobile menu button */}
                  <Disclosure.Button className="inline-flex items-center justify-center rounded-md bg-gray-800 p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
              </div>
            </div>
            <Disclosure.Panel className="md:hidden">
              <div className="space-y-1 px-2 pt-2 pb-3 sm:px-3">
                {navigation.map((item) => (
                  <Disclosure.Button
                    key={item.name}
                    as="a"
                    href={item.href}
                    className={classNames(
                      item.current
                        ? "bg-gray-900 text-white"
                        : "text-gray-300 hover:bg-gray-700 hover:text-white",
                      "block px-3 py-2 rounded-md text-base font-medium"
                    )}
                    aria-current={item.current ? "page" : undefined}
                  >
                    {item.name}
                  </Disclosure.Button>
                ))}
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
      <div className="min-h-full">
        <header className="bg-white shadow">
          <div className="mx-auto max-w-7xl py-6 px-4 sm:px-6 lg:px-8">
            <h1 className="text-3xl font-bold tracking-tight text-gray-900">
              Homepage
            </h1>
          </div>
        </header>
        <div className="py-2">
          <div className="mx-auto max-w-7xl py-4 mb-6">
            <CardL />
          </div>
        </div>

        <div className="mx-auto max-w-7xl py-6 sm:px-6 lg:px-8">
          <div className="px-4 py-6 sm:px-0 flex flex-col space-y-8 text-xs">
            <h2 className="text-xl font-bold tracking-tight text-gray-900 mb-2">
              Legacy content
            </h2>
            <p className="text-gray-700">
              <a
                href="/musicquiz"
                className="font-bold border-2 p-1 mr-4 bg-slate-200 
              hover:bg-slate-300"
              >
                Musicquiz
              </a>
              I am going to be ultra copyright destroyed
            </p>
            <p className="text-gray-700">
              <a
                href="/supermariorl"
                className="font-bold border-2 p-1 mr-4 bg-slate-200 
              hover:bg-slate-300"
              >
                Super Mario Irl
              </a>
              My first site, kept as a reminder that we all start somewhere
            </p>
          </div>
          {/* /End replace */}
        </div>
      </div>
    </>
  );
}
